import {gql} from "@apollo/client";

export const ProductSpecificationFragment = gql`
    fragment ProductSpecificationFragment on ProductSpecification {
		id
		parent_id
		position
		name
		value
    }
`

export const ProductSpecificationFragmentAdmin = gql`
    fragment ProductSpecificationFragmentAdmin on ProductSpecification {
		id
		parent_id
		position
		name
		names
		value
		values
    }
`

export const UpdateProductSpecification = gql`
	${ProductSpecificationFragment}
     mutation ($id: ID!, $name: String, $value: String) {
         UpdateProductSpecification (id: $id, name: $name, value: $value) {
			...ProductSpecificationFragment
         }
    }
`

