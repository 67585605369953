import {gql} from "@apollo/client";
import {StorageFragment} from "./Storage";
import {StorageFragmentAdmin} from "./Storage";

export const FilterFragment = gql`
	${StorageFragment}
    fragment FilterFragment on Filter {
		id
		name
		parent_id
		position
		display
		shop
		locked
		image 	 { 
			...StorageFragment 
		}
    }
`

export const FilterFragmentAdmin = gql`
	${StorageFragmentAdmin}
    fragment FilterFragmentAdmin on Filter {
		id
		name
		names
		parent_id
		position
		display
		shop
		locked
		image 	 { 
			...StorageFragmentAdmin 
		}
    }
`

export const GetFilters = gql`
	${FilterFragment}
     query ($search: String, $ids: [ID], $shop: Boolean) {
         GetFilters (search: $search, ids: $ids, shop: $shop) {
			...FilterFragment
			children 	 { 
				...FilterFragment 
			products_count
			}
         }
    }
`

export const GetFiltersAdmin = gql`
	${FilterFragmentAdmin}
     query ($search: String, $ids: [ID], $shop: Boolean) {
         GetFilters (search: $search, ids: $ids, shop: $shop) {
			...FilterFragmentAdmin
			children 	 { 
				...FilterFragmentAdmin 
			products_count
			}
         }
    }
`

export const GetFiltersWithParent = gql`
	${FilterFragment}
     query ($ids: [ID], $categories: [ID], $brands: [ID], $groupId: ID) {
         GetFiltersWithParent (ids: $ids, categories: $categories, brands: $brands, groupId: $groupId) {
			...FilterFragment
			parent 	 { 
				...FilterFragment 
			}
			products_count
         }
    }
`

export const GetFiltersWithParentAdmin = gql`
	${FilterFragmentAdmin}
     query ($ids: [ID], $categories: [ID], $brands: [ID], $groupId: ID) {
         GetFiltersWithParent (ids: $ids, categories: $categories, brands: $brands, groupId: $groupId) {
			...FilterFragmentAdmin
			parent 	 { 
				...FilterFragmentAdmin 
			}
			products_count
         }
    }
`

export const UpsertFilter = gql`
	${FilterFragment}
     mutation ($id: ID, $name: String, $parent_id: ID, $image_id: ID, $display: Boolean, $shop: Boolean) {
         UpsertFilter (id: $id, name: $name, parent_id: $parent_id, image_id: $image_id, display: $display, shop: $shop) {
			...FilterFragment
         }
    }
`

export const DeleteFilter = gql`
	${FilterFragment}
     mutation ($id: ID!) {
         DeleteFilter (id: $id) {
			...FilterFragment
         }
    }
`

