import {useQuery} from "@apollo/client";
import GraphQLErrorMessage from "../views/GraphQLErrorMessage";
import {GetProducts} from "./Product.js";

function useGetProducts(params = {}) {
    const {data, previousData, loading, error, refetch, variables} = useQuery(
        GetProducts, 
        {...params, notifyOnNetworkStatusChange: true}
    );
    
    let holder = (data && data['GetProducts']) ? data : previousData;
    const extracted = (holder) ? holder['GetProducts'].data : [];
    const paginatorInfo = (holder && holder['GetProducts']) ? holder['GetProducts'].paginatorInfo : {};
    return {
        data: extracted, paginatorInfo,
        loading: (loading && !previousData), 
        refetch, 
        variables,
        error: error ? <GraphQLErrorMessage error={error} /> : null
    }
}
export default useGetProducts;
