import toast from "react-hot-toast";
import {useEffect, useState} from "react";
import {Minus, Plus} from "react-feather";
import useGetProducts from "../../_graphql/compiled/useGetProducts";
import {randString} from "../../../framework/helpers";

//@@@ type=wrapper

const loadBasket = () =>  {
    let basket = localStorage.getItem("basket");
    if(!basket) return [];
    try {
        basket = JSON.parse(basket);
    } catch (e) {
        return [];
    }
    return basket;
}

function PreloadBasketWrapper({Next, context})  {
    const [load, setLoad] = useState(loadBasket());

    const {data} = useGetProducts({
        skip: load.length === 0,
        variables: {
            first: 100,
            ids: load.map(b => b.productId)
        }
    });

    useEffect(() => {
        if(!data) return;
        if(data.length === 0) return;
        for(let i=0; i<load.length; i++) {
            const find = data.find(p => p.id === load[i].productId);
            if (!find) {
                const newSave = load.filter(f => f.id !== load[i].id);
                localStorage.setItem("basket", JSON.stringify(newSave));
                setLoad(newSave);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    let basket = [];
    let total = 0;

    if(data) {
        for(let i=0; i<load.length; i++)    {
            const find = data.find(p => p.id === load[i].productId);
            if(!find) continue;
            total += find.process_price * load[i].qty;
            basket.push({...load[i], product: find});
        }
    }

    const add = (item, qty, filters = []) => {
        if(!qty) qty = 1;
        qty = parseInt(qty);
        const insert = {productId: item.id, qty, filters, id: randString()};
        setLoad([...load, insert]);
        localStorage.setItem("basket", JSON.stringify([...load, insert]));
        toast.success("dodato");
    }
    const remove = (id) => {
        const newLoad = load.filter(item => item.id !== id);
        setLoad(newLoad);
        localStorage.setItem("basket", JSON.stringify(newLoad));
    }
    const update = (id, sbj, value) => {
        if(sbj === "qty")   {
            if(value < 1)   return false;
            if(value > 10)  return false;
        }
        const newLoad = load.map(item => {
           if(item.id === id)   {
               return {...item, [sbj]: value};
           }
            return item;
        });
        setLoad(newLoad);
        localStorage.setItem("basket", JSON.stringify(newLoad));
    }
    const empty = () => {
        setLoad([]);
        localStorage.removeItem("basket");
    }

    const Qty = ({id}) => {
        const product = basket.find(item => item.id === id);
        if(!product) return null;
        return <div className="inline-block">
            <div className="flex">
                <button onClick={() => update(id, "qty", product.qty-1)}
                        className="text-sm bg-light-1 dark:bg-dark-1 px-3 rounded-l-md border border-black border-r-0"><Minus size={16} strokeWidth={3} /></button>
                <div className="inline-block text-sm bg-light-1 dark:bg-dark-1 py-2 px-3 border border-black items-center font-bold"> {product.qty} </div>
                <button onClick={() => update(id, "qty", product.qty+1)}
                        className="text-sm bg-light-1 dark:bg-dark-1 px-3 rounded-r-md border border-black border-l-0"><Plus size={16} strokeWidth={3} /></button>
            </div>
        </div>
    }

    return <Next context={{...context,
        basket,
        Qty,
        addToBasket: add,
        removeFromBasket: remove,
        updateBasket: update,
        totalBasket: total,
        emptyBasket: empty}} />
}
export default PreloadBasketWrapper;