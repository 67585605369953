import {gql} from "@apollo/client";
import {StorageFragment} from "./Storage";
import {StorageFragmentAdmin} from "./Storage";
import {PAGINATOR_INFO_FRAGMENT} from "../fragments/PaginatorInfoFragment";

export const CategoryFragment = gql`
	${StorageFragment}
    fragment CategoryFragment on Category {
		id
		name
		slug
		description
		parent_id
		position
		seo_title
		seo_keywords
		seo_description
		image 	 { 
			...StorageFragment 
		}
    }
`

export const CategoryFragmentAdmin = gql`
	${StorageFragmentAdmin}
    fragment CategoryFragmentAdmin on Category {
		id
		name
		slug
		names
		slugs
		description
		descriptions
		parent_id
		position
		seo_title
		seo_keywords
		seo_description
		seo_titles
		seo_keywords_all
		seo_descriptions
		image 	 { 
			...StorageFragmentAdmin 
		}
    }
`

export const GetCategories = gql`
	${CategoryFragment}
     query ($limit: Int, $search: String, $brand: String) {
         GetCategories (limit: $limit, search: $search, brand: $brand) {
			...CategoryFragment
			products_count
         }
    }
`

export const GetCategoriesAdmin = gql`
	${CategoryFragmentAdmin}
     query ($limit: Int, $search: String, $brand: String) {
         GetCategories (limit: $limit, search: $search, brand: $brand) {
			...CategoryFragmentAdmin
			products_count
         }
    }
`

export const GetCategoriesWithPagination = gql`
	${CategoryFragment}
	${PAGINATOR_INFO_FRAGMENT}
     query ($page: Int, $first: Int, $search: String, $order: String, $start_date: String, $end_date: String) {
         GetCategoriesWithPagination (page: $page, first: $first, search: $search, order: $order, start_date: $start_date, end_date: $end_date) {
			data {
			...CategoryFragment
			products_count
			products_views
			wishlist_added
			order_products_count
			order_products_total
			}
			paginatorInfo {
				...PaginatorInfoFragment
			}
         }
    }
`

export const GetCategoriesWithPaginationAdmin = gql`
	${CategoryFragmentAdmin}
	${PAGINATOR_INFO_FRAGMENT}
     query ($page: Int, $first: Int, $search: String, $order: String, $start_date: String, $end_date: String) {
         GetCategoriesWithPagination (page: $page, first: $first, search: $search, order: $order, start_date: $start_date, end_date: $end_date) {
			data {
			...CategoryFragmentAdmin
			products_count
			products_views
			wishlist_added
			order_products_count
			order_products_total
			}
			paginatorInfo {
				...PaginatorInfoFragment
			}
         }
    }
`

export const GetShopCategories = gql`
	${CategoryFragment}
     query ($limit: Int, $search: String, $brand: String, $groupId: ID) {
         GetShopCategories (limit: $limit, search: $search, brand: $brand, groupId: $groupId) {
			...CategoryFragment
			shop_products_count
         }
    }
`

export const GetShopCategoriesAdmin = gql`
	${CategoryFragmentAdmin}
     query ($limit: Int, $search: String, $brand: String, $groupId: ID) {
         GetShopCategories (limit: $limit, search: $search, brand: $brand, groupId: $groupId) {
			...CategoryFragmentAdmin
			shop_products_count
         }
    }
`

export const UpsertCategory = gql`
	${CategoryFragment}
     mutation ($id: ID, $name: String, $slug: String, $parent_id: ID, $image_id: ID, $seo_title: String, $seo_keywords: String, $seo_description: String, $description: String) {
         UpsertCategory (id: $id, name: $name, slug: $slug, parent_id: $parent_id, image_id: $image_id, seo_title: $seo_title, seo_keywords: $seo_keywords, seo_description: $seo_description, description: $description) {
			...CategoryFragment
         }
    }
`

export const DeleteCategory = gql`
	${CategoryFragment}
     mutation ($id: ID!) {
         DeleteCategory (id: $id) {
			...CategoryFragment
         }
    }
`

